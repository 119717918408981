@use "src/common/typography";
@use "src/common/common";

.info-popup {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;

  &_opened {
    visibility: visible;
    opacity: 1;
  }

  &__container {
    padding: 10px 15px;
    background-color: var(--second-accent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 7px;
    border: 2px solid var(--border-dark);
  }

  &__text {
    @include typography.title4;

    color: var(--dark);
    margin: 0;
  }

  &__close-btn {
    border: 1px solid var(--dark);
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    background-image: url("../../../assets//images/icons/close-icon.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include common.media(md) {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
