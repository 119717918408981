@use "src/common/typography";

.input {
  $root: &;
  @include typography.text1;

  all: unset;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: var(--dark);
  transition: border-bottom var(--base-transition);
  padding: 15px 8px;
  min-height: 50px;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
    border: 2px solid var(--second-accent);

    &#{$root}_is-warning {
      border: 2px solid var(--warning);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type="date"] {
    width: 100%;
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &::placeholder {
    color: var(--gray);
  }

  &_is-bordered {
    border: 2px solid var(--border-dark);
    border-radius: 7px;
  }

  &_is-warning {
    color: var(--warning);
  }
}
