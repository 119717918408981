@use "src/common/typography";

.tag {
  align-self: center;
  justify-self: start;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 7px;
  border-radius: 4px;
  color: var(--dark);
  background-color: var(--light-gray);

  span {
    @include typography.text2-semi-bold;
  }

  img {
    width: 15px;
    height: 15px;
  }
}
