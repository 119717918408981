.card-label {
  width: 35px;
  height: 35px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  right: 5%;
  bottom: 0;
  transform: translateY(40%);
  background-image: url("../../../../assets/images/icons/goal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  filter: grayscale(0.2);
  border: 2px solid var(--border-dark);

  &_type_nature {
    background-image: url("../../../../assets/images/icons/nature.png");
  }

  &_type_sport {
    background-image: url("../../../../assets/images/icons/ball.png");
  }

  &_type_beauty {
    background-image: url("../../../../assets/images/icons/beauty.png");
  }
}
