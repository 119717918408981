@use "src/common/typography";
@use "src/common/common";

.header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 9;
  display: flex;
  justify-content: center;

  &__nav {
    // background-image: var(--main-gradient);
    border: 2px solid var(--border-dark);
    box-sizing: border-box;
    box-shadow: var(--shadow);
    padding: 20px 0 10px;
    background-color: var(--second-accent);
    display: flex;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    border-radius: 10px;
    margin: 0 0 20px;

    @include common.media(s) {
      border-radius: 0;
      border: 0;
      border-top: 2px solid var(--border-dark);
      margin: 0;
    }

    &-list {
      @include common.reset-list;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      gap: 20px;
      padding: 0 20px;
      width: 100%;
    }

    &-link {
      @include typography.text3;

      text-align: center;
      color: var(--dark);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;
      padding: 0;
      box-sizing: border-box;
      position: relative;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &_is-active {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: -30%;
          left: 50%;
          transform: translate(-50%);
          width: 5px;
          height: 5px;
          background-color: var(--white);
          border-radius: 50%;
        }
      }
    }

    &-icon {
      max-width: 20px;
      max-height: 20px;
      object-fit: cover;
      object-position: center;
      filter: brightness(0) invert(1);
    }
  }
}
