@use "src/common/typography";
@use "src/common/common";

.progress-bar {
  position: relative;
  display: flex;
  width: 100%;

  &__text {
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: 2;
    word-break: normal;
    @include typography.text2;

    border-radius: 3px;
    padding: 2px 4px;
    background-color: var(--light-gray);
    color: var(--smoke);
  }

  &__bar[value] {
    align-self: center;
    border-radius: var(--progress-rd);
    width: 100%;
    height: 7px;
    margin: 0;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-progress-bar {
      background-color: var(--light-gray);
      border-radius: var(--progress-rd);
    }
    &::-webkit-progress-value {
      background-color: var(--second-accent);
      border-radius: var(--progress-rd);
    }
    &::-moz-progress-bar {
      /* style rules */
    }
  }
}
