@use "src/common/typography";

.team-user-rating-row {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 5px;
  border-radius: 3px;

  &._mine {
    background-color: var(--second-accent-5);
  }

  &._gold {
    background-color: var(--gold);
  }

  // &._mine {
  //   background-color: var(--light-gray);
  // }

  &__avatar {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--white);
    padding: 1px;
  }

  &__name {
    flex-grow: 1;
  }

  span {
    @include typography.title4;
  }
}
