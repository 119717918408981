:root {
  /*  ----------   spacings   ------------  */
  --offside-xl: 70px;
  --offside-md: 50px;
  --offside-s: 24px;
  --pd-xl: 110px;
  --pd-md: 90px;
  --pd-top-xs: 50px;
  --pd-bottom-xs: 80px;
  --block-space-xl: var(--pd-xl) var(--offside-xl);
  --block-space-md: var(--pd-md) var(--offside-md);
  --movies-space-md: 80px 30px;
  --block-space-s: var(--pd-top-xs) var(--offside-s) var(--pd-bottom-xs);
  --block-form-space: var(--pd-top-xs) 30px var(--pd-bottom-xs);
  /*  ----------   general   ------------  */
  --width-xl: 1140px;
  --max-width-s: 600px;
  /*  ----------   colors   ------------  */
  --white: #fff;
  --black: #222;
  --dark: #272727;
  --smoke: #2f2f2f;
  --gray: #8b8b8b;
  --active-gray: #d7d4d4;
  --light-gray: rgb(239, 237, 237);
  --light-gray-8: rgb(239, 237, 237, 0.8);
  --main-bg-color: #fcfcfc;
  --main-gradient: linear-gradient(
    160deg,
    var(--main-accent) 0%,
    var(--second-accent) 100%
  );
  --colonial-blue: #073042;
  --footer-bg-color: var(--main-bg-color);
  --logo-bg-color: #313131;
  --logo-main-bg-color: #0f4157;
  --border-light: #dadada;
  --border-dark: #424242;
  --card-bg-gray: #303030;
  --main-accent: #0093e9;
  --text_disabled: #969696;
  --submit_disabled: #142a4e;
  --second-accent: #80d0c7;
  --second-accent-5: #80d0c73e;
  --active-second-accent: #6db0a9;
  --warning: #eb7897;
  --complete: #a9e4c2;
  --gold: #fff584;
  --silver: #cbcbcb;
  --bronze: #c27b1e;
  --base-transition: 0.3s ease-out;
  --link-hover-opacity: 0.7;
  --btn-hover-opacity: 0.8;
  --shadow: rgba(255, 255, 255, 0.8) 0px 5px 15px;
  --progress-rd: 2px;
  --btn-rd: 5px;
}
