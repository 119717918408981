.form {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  /* min-height: 280px; */

  &__inner-container {
    padding: 0;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: none;
    margin: 0;
    width: 100%;
  }
}

.form__handles-container {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.form__response {
  opacity: 0;
  visibility: hidden;
  color: var(--second-accent);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 14px;
  margin: 0 0 18px;
}
.form__response_is-error {
  color: var(--warning);
}
.form__response_visible {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 1023px) {
  .form {
    justify-content: space-between;
  }
}
@media screen and (max-width: 600px) {
  .form__handles-container {
    /* margin-top: auto; */
  }

  .form__inner-container {
    margin: 0 0 0;
  }
}
