@use "src/common/common";
@use "src/common/typography";

.main {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    max-width: 800px;
    margin: 0 auto;
  }

  &__handles {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__button {
    font-weight: 400;
  }
}

.promo {
  text-align: center;

  &__title {
    @include typography.slogan;

    margin: 90px auto 30px;
    color: var(--dark);

    span {
      font-weight: 600;
    }
  }

  &__description {
    @include typography.text1;

    margin: 0;
  }
}

.team-description {
  padding: 60px var(--offside-s) 60px;
  margin: var(--pd-top-xs) auto var(--pd-bottom-xs);
  background-color: var(--second-accent);
  display: flex;
  justify-content: center;

  &__title {
    @include typography.title1;
    font-weight: 400;
    color: var(--dark);
    display: flex;
    flex-direction: column;
    max-width: 800px;

    b {
      font-weight: 600;
    }
  }
}

.goal-description {
  padding: 0 var(--offside-s) 0;
  margin: 0 auto var(--pd-bottom-xs);
  display: flex;
  justify-content: center;

  &__title {
    @include typography.title1;
    font-weight: 400;
    color: var(--dark);
    display: flex;
    flex-direction: column;
    text-align: right;
    max-width: 800px;

    b {
      font-weight: 600;
      color: var(--second-accent);
    }
  }
}

.footer {
  margin: 15px 24px;

  &__copyright {
    @include typography.text1;

    margin: 0;
    text-align: center;
    display: block;
  }
}
