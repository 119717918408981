@keyframes translate {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(110%);
  }
  40% {
    transform: translateX(110%);
  }
  60% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}

.preload {
  --animation: translate 3.7s ease-in-out infinite;
  --first: 1s;
  --second: 2s;
  --third: 3s;
  --fourth: 4s;

  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 999;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    // background-image: url("../../../assets/images/icons/crest.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
  }

  &__container {
    position: fixed;
    inset: 0;
    background-color: var(--light-gray-8);
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 999;
    backdrop-filter: blur(10px);
    overflow: hidden;
  }

  &__white,
  &__green {
    position: absolute;
    display: inline-block;
    width: 59%;
    height: 59%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 96%;
    }

    &_pos_left,
    &_pos_right {
      &::before {
        transform: translate(0);
        animation: var(--animation);
        width: 100%;
        height: 100%;
      }
    }

    &_pos_left {
      left: 0;
    }

    &_pos_right {
      right: 0;
    }
  }

  &__green {
    &::before {
      background-image: url("../../../assets/images/icons/rect-green-w.svg");
    }

    &_pos_left {
      top: 0;
      left: 0;

      &::before {
        animation-delay: var(--first);
      }
    }

    &_pos_right {
      bottom: 0;
      transform: rotate(180deg);

      &::before {
        animation-delay: var(--third);
      }
    }
  }

  &__white {
    &::before {
      background-image: url("../../../assets/images/icons/rect-white-w.svg");
    }

    &_pos_left {
      bottom: 0;
      transform: rotate(270deg);

      &::before {
        animation-delay: var(--fourth);
      }
    }

    &_pos_right {
      top: 0;
      transform: rotate(90deg);

      &::before {
        animation-delay: var(--second);
      }
    }
  }
}
