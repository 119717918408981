@mixin slogan {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin title1-bold {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 1;
}

@mixin title1-semi-bold {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin title1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin title3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
}

@mixin title3-bold {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
}

@mixin title4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
}

@mixin title4-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1;
}

@mixin text1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

@mixin text1-semi-bold {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

@mixin text1-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1;
}

@mixin text2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1;
}

@mixin text2-semi-bold {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
}

@mixin text2-bold {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.1;
}

@mixin text3 {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.1;
}

@mixin text3-bold {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.1;
}

@mixin text3-semi-bold {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.1;
}

@mixin text4 {
  font-size: 8px;
  font-weight: 400;
  line-height: 1.1;
}

@mixin text4-bold {
  font-size: 8px;
  font-weight: 500;
  line-height: 1.2;
}
