@mixin reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}

@mixin bg-main-gradient {
  background-color: var(--main-accent);
  background-image: var(--main-gradient);
}

@mixin media($breakpoint) {
  @if $breakpoint == md {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  }

  @if $breakpoint == s {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
}
