@use "src/common/typography";
@use "src/common/common";

.fill-goal {
  &__tabs {
    display: grid;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }

  &__residue-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__residue-title {
    @include typography.text2;
    color: var(--dark);
  }

  &__residue-count {
    display: flex;
    gap: 10px;
  }

  &__residue-number-container {
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }

  &__residue-number {
    @include typography.title3;
    color: var(--dark);
  }

  &__residue-caption {
    @include typography.text2;
    color: var(--dark);
    margin-left: 0px;
  }

  &__max-value {
    @include typography.text2;
    color: var(--dark);
    margin-bottom: 25px;
    padding: 6px 8px;
    background-color: var(--second-accent);
    border-radius: 5px;
  }
}
.goal {
  &__count-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__counter {
    display: flex;
    align-items: center;
  }

  &__input-count {
    @include typography.title4;

    padding: 0;
    border: none;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  &__count-title {
    @include typography.title4;

    width: 100%;
    margin: 10px 0 15px;
  }

  &__count-handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__max-count {
    @include typography.text2;
    color: var(--gray);
    margin-left: 0px;
  }
}
