@use "src/common/common";

.goal {
  &__header {
    margin: 0 0 40px;
    padding: 20px var(--offside-s);
    background-color: var(--light-gray);
    border-bottom: 2px solid var(--border-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__header-inner {
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    color: var(--dark);
  }

  &__inner {
    padding: 50px 30px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include common.media(s) {
      padding: var(--block-form-space);
    }
  }
}
