@use "src/common/common";
@use "src/common/typography";

.goals-handlers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__tabs {
    display: grid;
    width: 100%;
    max-width: 600px;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 var(--offside-s);
    margin-bottom: 10px;
  }

  &__team-filter {
    align-self: flex-start;
    padding: 0 var(--offside-s);
    margin: 20px auto 0;
    display: flex;
    overflow: auto;
    max-width: 600px;
    width: 100%;
    gap: 10px;
  }

  &__team-btn {
    min-width: 80px;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}
