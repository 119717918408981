@use "src/common/typography";

.day-tag {
  @include typography.text2;
  align-self: center;
  justify-self: start;
  padding: 3px 6px;
  border-radius: 3px;
  margin: 0;
  color: var(--white);
  background-color: var(--second-accent);

  &_is-overdue {
    background-color: var(--warning);
    color: var(--white);
  }
}
