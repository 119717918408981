@use "src/common/typography";

.radio-tag {
  @include typography.text2;
  background-color: var(--light-gray);
  padding: 5px 7px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  img {
    width: 12px;
    height: 12px;
  }

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  &:active {
    background-color: var(--active-gray);
  }

  &__input {
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    &:checked {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border: 2px solid var(--second-accent);
        border-radius: 4px;
      }
    }
  }
}
