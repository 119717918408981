@use "src/common/typography";
@use "src/common/common";

.card-progress {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__text {
    @include typography.text2;

    border-radius: 3px;
    justify-self: start;
    padding: 3px 6px;
    background-color: var(--light-gray);
    color: var(--smoke);
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
  }
}
