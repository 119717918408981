@use "src/common/common";
@use "src/common/typography";

.goal {
  &__title {
    margin: 0;
    color: var(--dark);
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__create-goal {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 2px solid var(--light-gray);
    max-width: 400px;
    width: 100%;
  }

  &__radio-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
  }

  &__checkbox {
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    &:checked {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border: 2px solid var(--second-accent);
        border-radius: 4px;
      }
    }
  }

  &__checkbox &__checkbox-label-name {
    background-color: black;
    border: 2px solid black;
  }

  &__checkbox-label {
    @include typography.text2;
    background-color: var(--light-gray);
    padding: 5px 7px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    &:focus-within {
      // background-color: black;
    }
  }

  &__checkbox-label-icon {
    width: 12px;
    height: 12px;
  }

  &__file-wrapper {
    position: relative;
  }

  &__file-container {
    border-radius: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--border-dark);
    padding: 20px 0;
    position: relative;
    overflow: hidden;
  }

  &__file-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-color: var(--white);
  }

  &__file-title {
    max-width: 70%;
    color: var(--gray);
    position: relative;
    display: flex;
    gap: 10px;
  }

  &__file-text {
    gap: 3px;
    @include typography.text2;
    margin: 0;
  }

  &__file-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
    opacity: 0.3;
  }

  &__file-close {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    background: var(--white);
    border: 1px solid var(--dark);
    border-radius: 50%;
    transition: opacity 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/images/icons/close-icon.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 0 30px;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__unit-block {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 10px;
  }

  &__limit-title {
    @include typography.text2;
    display: inline-block;
    margin-bottom: 20px;
  }

  &__limit-block {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 10px;
  }

  &__handles-container {
    margin-top: 30px;
    width: 100%;
  }
}
