@use "src/common/typography";

.confirm-popup {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  max-width: 100vw;
  max-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 10;

  &_opened {
    visibility: visible;
    opacity: 1;
  }

  &__container {
    padding: 15px 15px 15px;
    border-radius: 7px;
    background-color: var(--white);
    width: 70%;
    margin: 0 auto;
    max-width: 430px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__title {
    @include typography.title3;
    margin: 0 0 30px;
  }
}
