@use "src/common/typography";

.spoiler {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--border-dark);
  padding: 10px 10px;
  border-radius: 7px;
  max-width: 400px;
  width: 100%;
  margin-bottom: 15px;

  &__subtitle {
    @include typography.title4;

    flex-grow: 1;
    margin: 0;
  }

  &__outer {
    height: 0;
    transition: height 0.3s ease-out;
    width: 100%;
    overflow: hidden;
  }

  &__inner {
    padding-top: 20px;
  }
}
