@use "src/common/common";
@use "src/common/typography";

.goals {
  // margin: var(--block-space-s);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 var(--pd-bottom-xs);
  align-items: center;

  &__header {
    margin: 0 0 40px;
    padding: 20px var(--offside-s);
    background-color: var(--light-gray);
    border-bottom: 2px solid var(--border-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__header-inner {
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    max-width: 400px;
    width: 100%;
  }

  &__empty {
    @include typography.text1;
    color: var(--dark);
    margin: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tabs {
    display: grid;
    width: 100%;
    max-width: 600px;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 var(--offside-s);
  }

  &__team-filter {
    align-self: flex-start;
    padding: 0 var(--offside-s);
    margin: 0 auto;
    display: flex;
    overflow: auto;
    max-width: 600px;
    width: 100%;
    gap: 10px;
  }

  &__team-btn {
    min-width: 80px;
  }
}

.goals__title {
  margin: 0;
  color: var(--dark);
}

.goals__list {
  @include common.reset-list;
  padding: var(--block-space-s);
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 20px;
}
