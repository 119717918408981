@use "src/common/typography";

.team-card {
  &__handlers {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__team-icon {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
  }

  &__count {
    @include typography.text1;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-right: auto;
    flex-shrink: 0;
    color: var(--dark);
  }

  &__rating-list {
    display: grid;
    gap: 5px;
  }

  &__admin {
    position: relative;
  }

  &__admin-caption {
    @include typography.text3;
    text-align: center;
    position: absolute;
    width: 140px;
    transform: translate(-50%, -150%);
    left: 0;
    word-break: keep-all;
    background-color: var(--second-accent);
    padding: 6px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--base-transition),
      visibility var(--base-transition), transform var(--base-transition);

    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: inherit;
      top: 15%;
      left: 50%;
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  &__admin-caption._opened {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -130%);
  }
}
