.edit-goal-form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: center;
  width: 100%;

  &__delete {
    margin: 40px 0 0;
    flex-basis: 35%;
  }

  &__save {
    margin: 40px 0 0;
    flex-grow: 1;
  }
}
