@use "src/common/common";
@use "src/common/typography";

.profile {
  display: grid;
  width: 100%;
  justify-items: center;
  padding: 70px 0;
  box-sizing: border-box;
  gap: 30px;

  @include common.media(m) {
    padding-bottom: 30px;
  }

  @include common.media(s) {
    padding: 60px 30px 30px;
    align-items: center;
  }

  &__logo-link {
    display: flex;
    align-items: center;
  }

  &__inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    max-width: 410px;
    width: 100%;
    margin-bottom: 80px;
  }

  &__about {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    // align-self: flex-start;

    margin-bottom: 105px;

    @include common.media(m) {
      margin-bottom: 90px;
    }

    @include common.media(s) {
      margin-bottom: 50px;
    }
  }

  &__about-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // align-self: flex-start;
  }

  &__about-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__about-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__goals-count {
    @include typography.text3;
    margin: 0;
    color: var(--gray);
  }

  &__buttons {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
  }

  &__signout {
    color: var(--dark);
  }

  &__edit {
    flex-grow: 1;
    color: var(--dark);
  }

  &__form {
    width: 100%;
  }
}

.preload-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(5px);
  max-width: 100vw;
  max-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  &__title {
    @include typography.slogan;
    color: var(--dark);
    text-transform: uppercase;
    display: flex;
    gap: 2px;

    span {
      color: var(--second-accent);
      font-weight: 600;
      border-bottom: 6px solid var(--second-accent);
    }
  }
}
