@use "src/common/common";
@use "src/common/typography";

.team-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border: 2px solid var(--border-dark);
  border-radius: 7px;
  padding: 8px;

  &__name {
    @include typography.title4;
    flex-grow: 1;
  }

  &__count {
    @include typography.text1;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--light-gray);
    padding: 4px 6px;
    border-radius: 4px;
    margin-right: auto;
    flex-shrink: 0;
  }

  &__team-icon {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
  }

  &__admin-icon {
    width: 24px;
    height: 24px;
    fill: var(--light);
    background-color: var(--second-accent);
    padding: 3px 3px;
    border-radius: 4px;
    background-image: url("../../../assets/images/icons/admin.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
  }
}
