@use "src/common/typography";
@use "src/common/common";

.show-team-popup {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.465);
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  z-index: 9;

  &_opened {
    visibility: visible;
    opacity: 1;
  }

  &__container {
    padding: 20px;
    background-color: var(--white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    border-radius: 7px;
    border: 2px solid var(--border-dark);
    width: 90%;
  }

  &__title {
    color: var(--dark);
    @include typography.title3-bold;
    margin: 0;
    display: inline-flex;
  }

  &__name {
    @include typography.text2;
  }

  &__progress {
    align-self: center;
    border-radius: var(--progress-rd);
    width: 100%;
    height: 7px;
    margin: 0;

    &::-webkit-progress-bar {
      background-color: var(--light-gray);
      border-radius: var(--progress-rd);
    }
    &::-webkit-progress-value {
      background-color: var(--second-accent);
      border-radius: var(--progress-rd);
    }
    &::-moz-progress-bar {
      /* style rules */
    }
  }

  &__list {
    flex-grow: 1;
    display: grid;
    gap: 15px;
    margin-top: 20px;
    width: 100%;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }

  &__progress {
    width: 100%;
  }

  &__text {
    @include typography.title4;

    color: var(--dark);
    margin: 0;
  }

  &__close-btn {
    align-self: end;
    border: 1px solid var(--dark);
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    background-image: url("../../../assets//images/icons/close-icon.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include common.media(md) {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
