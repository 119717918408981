.not-found {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 60px 0;
}
.not-found__inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 184px;
}
.not-found__title {
  margin: 0;
  color: var(--white);
  text-align: center;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.not-found__text {
  margin: 5px 0 0;
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.not-found__button {
  margin: 0;
  background-color: transparent;
  color: var(--second-accent);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-self: center;
}

@media screen and (max-width: 600px) {
  .not-found {
    padding: 30px 0;
  }
  .not-found__inner-container {
    flex-grow: 1;
    margin-bottom: 0px;
  }
  .not-found__title {
    font-size: 80px;
  }
  .not-found__text {
    font-size: 12px;
    margin: 10px 0 0;
  }
}
