@use "src/common/typography";
@use "src/common/common";

.select__container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 20px;

  &::before {
    @include common.media(md) {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-image: url("../../../assets/images/icons/list.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.input__label {
  font-style: normal;
  line-height: normal;
}

.input__label_pos_top {
  @include typography.text3;
  color: var(--dark);
  padding: 2px;
}

.input__label_pos_center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  font-size: 11px;
  font-weight: 500;
}

.input__error {
  --error-font: 10px;

  margin-top: 4px;
  display: inline-block;
  color: var(--warning);
  font-size: var(--error-font);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  min-height: calc(var(--error-font) * 2);
}
.select {
  $root: &;
  @include typography.text1;

  all: unset;
  // width: 100%;
  height: 93%;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: var(--dark);
  transition: border-bottom var(--base-transition);
  padding: 15px 8px;
  min-height: 50px;
  -webkit-appearance: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 2px solid var(--second-accent);

    &#{$root}_is-warning {
      border: 2px solid var(--warning);
    }
  }

  &::placeholder {
    color: var(--gray);
  }

  &_is-bordered {
    border: 2px solid var(--border-dark);
    border-radius: 7px;
  }

  &_is-warning {
    color: var(--warning);
  }
}
