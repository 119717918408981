* {
  box-sizing: border-box;
  word-break: break-word;
}

.root {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  background-color: var(--main-bg-color);
  position: relative;
}
.root_blocked {
  height: 100vw;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.link {
  transition: opacity var(--base-transition);
}

.link:hover {
  opacity: var(--link-hover-opacity);
}

.link:active,
.link:focus {
  opacity: 1;
}

.button_col_green {
  background-color: var(--main-accent);
}

.disabledBtn {
  pointer-events: none;
  opacity: 0.4;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
