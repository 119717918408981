@use "src/common/common";
@use "src/common/typography";

.profile-chart {
  margin: 0 0 20px;
  width: 100%;
  max-width: 400px;

  &__subtitle {
    margin: 0 0 20px;
  }
}
