@use "src/common/common";
@use "src/common/typography";

.submit {
  @include common.button;
  @include typography.text1-semi-bold;

  padding: 14px 5px;
  width: 100%;
  border-radius: var(--btn-rd);
  color: var(--dark);
  background-color: var(--second-accent);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity var(--base-transition);

  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:active {
    background-color: var(--active-second-accent);
  }
}
