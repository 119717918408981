@use "src/common/common";
@use "src/common/typography";

.counter-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border: 2px solid var(--border-dark);
  border-radius: 7px;
  padding: 8px;

  &__name,
  &__count {
    @include typography.title4;
  }

  &__count {
    margin-left: auto;
  }
}
