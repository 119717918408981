@use "src/common/common";
@use "src/common/typography";

.button-rounded {
  @include common.button;
  @include typography.text1;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--dark);
  padding: 12px 10px;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity var(--base-transition);

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button-rounded_type_gray {
  background-color: var(--light-gray);
  color: var(--dark);

  &:active {
    background-color: var(--active-gray);
  }

  &._active {
    color: var(--white);
    background-color: var(--second-accent);
  }
}

.button-rounded_type_light-bordered {
  background-color: var(--light-gray);
  border: 2px solid var(--light-gray);

  &:active {
    background-color: var(--active-gray);
  }

  &._active {
    border: 2px solid var(--second-accent);
  }
}

.button-rounded_type_blue {
  background-color: var(--second-accent);
  color: var(--dark);

  &:active {
    background-color: var(--active-second-accent);
  }
}

.button-rounded_type_green {
  background-color: var(--second-accent);
  color: var(--dark);

  &:active {
    background-color: #609d96;
  }
}

.button-rounded_icon_edit,
.button-rounded_icon_complete,
.button-rounded_icon_user,
.button-rounded_icon_google {
  display: flex;
  align-items: center;
  gap: 5px;

  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.button-rounded_icon_edit {
  &::before {
    background-image: url("../../../assets/images/icons/edit-text.png");
  }
}

.button-rounded_icon_complete {
  &::before {
    background-image: url("../../../assets/images/icons/accept.png");
  }
}

.button-rounded_icon_user {
  &::before {
    background-image: url("../../../assets/images/icons/user-white.png");
  }
}

.button-rounded_icon_google {
  gap: 10px;

  &::before {
    background-image: url("../../../assets/images/icons/google.png");
  }
}
