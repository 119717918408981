@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;

  &::before {
    content: "";
    top: -20%;
    left: -20%;
    position: absolute;
    height: 120%;
    width: 120%;
    border-radius: 7px;
    border: 3px solid transparent;
    z-index: 0;
    background-color: var(--light-gray);
  }

  &__progress {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid var(--dark);
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--second-accent);
      top: -3px;
      left: -3px;
      animation: spin 1s linear infinite;
    }
  }
}
