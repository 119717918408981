@use "src/common/typography";
@use "src/common/common";

.edit-counter {
  &__tabs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
.goal {
  &__count-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__counter {
    display: flex;
    align-items: center;
  }

  &__input-count {
    @include typography.title4;

    padding: 0;
    border: none;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  &__count-title {
    @include typography.title4;

    width: 100%;
    margin: 10px 0 15px;
  }

  &__count-handlers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__max-count {
    @include typography.text2;
    color: var(--gray);
    margin-left: 0px;
  }
}
