@use "src/common/common";
@use "src/common/typography";

.profile-list-block {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  &__subtitle {
    margin-bottom: 20px;
  }

  &__list {
    display: grid;
  }

  &__text {
    @include typography.text1;
    margin: 0;
    color: var(--dark);
    text-align: center;
  }
}
