@use "src/common/common";
@use "src/common/typography";

.register {
  flex-grow: 1;
  display: flex;
  width: 100%;

  &__inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 70px 0;
    max-width: 396px;
    margin: auto;

    @include common.media(md) {
      padding: 45px 30px 30px;
    }

    @include common.media(s) {
      align-items: center;
      padding: 56px 30px 20px;
    }
  }

  &__form {
    width: 100%;
  }

  &__text {
    @include typography.text1;

    color: var(--gray);
    text-align: center;
    align-self: center;
    margin: 16px 0 0;

    @include common.media(s) {
      margin: 14px 0 0;
    }
  }

  &__title {
    margin-bottom: 70px;
    align-self: flex-start;
  }

  &__link {
    color: var(--second-accent);
  }

  &__logo-link {
    align-self: flex-start;
    margin-bottom: 40px;
  }
}
