@use "src/common/common";

.input__container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.input__container_type_date {
  margin-bottom: 20px;
}

.input__container_type_date::before {
  @include common.media(md) {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("../../../assets/images/icons/calendar.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.input__label {
  font-style: normal;
  line-height: normal;
}

.input__label_pos_top {
  position: absolute;
  top: 0%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--white);
  background-color: var(--main-bg-color);
  font-size: 12px;
  font-weight: 500;
}

.input__label_pos_center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  font-size: 11px;
  font-weight: 500;
}

.input__error {
  --error-font: 10px;

  margin-top: 4px;
  display: inline-block;
  color: var(--warning);
  font-size: var(--error-font);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  min-height: calc(var(--error-font) * 2);
}
