@use "src/common/typography";
@use "src/common/common";

.card {
  padding: 0;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  transition: opacity var(--base-transition);
  border: 2px solid var(--border-dark);
  overflow: hidden;

  &_is-completed {
    opacity: 0;
  }

  &__inner-container {
    padding: 15px 10px 10px;
    display: grid;
    column-gap: 15px;
    position: relative;
  }

  &__bg-container {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: center;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  &__title-container {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    position: relative;
    margin: 0;
    border-bottom: 2px solid var(--border-dark);
  }
  &__image-container {
    width: 35px;
    height: 35px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    right: 5%;
    bottom: 0;
    transform: translateY(40%);
    background-image: url("../../../assets/images/icons/goal.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    filter: grayscale(0.2);
    border: 2px solid var(--border-dark);

    &_type_nature {
      background-image: url("../../../assets/images/icons/nature.png");
    }

    &_type_sport {
      background-image: url("../../../assets/images/icons/ball.png");
    }

    &_type_beauty {
      background-image: url("../../../assets/images/icons/beauty.png");
    }
  }
  &__image {
    width: 60%;
    height: 60%;
    object-fit: contain;
    object-position: center;
    opacity: 0.5;
  }

  &__progress {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__progress-text {
    @include typography.text2;

    border-radius: 3px;
    justify-self: start;
    padding: 3px 6px;
    background-color: var(--light-gray);
    color: var(--smoke);
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
  }

  &__title {
    @include typography.title4-bold;

    margin: 0;
    color: var(--dark);
    max-width: 85%;
  }

  &__tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    z-index: 1;
  }

  &__team {
    align-self: center;
    justify-self: start;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 3px 6px;
    border-radius: 3px;
    color: var(--dark);
    background-color: var(--light-gray);
    // border: 2px solid var(--border-dark);

    span {
      @include typography.text2-semi-bold;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }

  &__handlers {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 10px;
    position: relative;
    z-index: 1;
  }

  &__edit {
    @include typography.text2;

    background-color: var(--light-gray);
    padding: 12px 10px;
    border-radius: 7px;
    color: var(--dark);
    flex-grow: 1;
  }

  &__complete {
    align-self: end;
    justify-self: end;
  }
}
