@use "src/common/common";
@use "src/common/typography";

.join-team-form {
  &__examples {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 20px;
    gap: 5px 7px;
  }

  &__add-btn-container {
    display: flex;
    align-items: center;
  }

  &__add-btn {
    @include common.button;
    height: 24px;
    width: 34px;
    background-color: var(--second-accent);
    background-image: url("../../..//assets/images/icons/plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
    border-radius: 4px;
  }
}
