@use "src/common/common";
@use "src/common/typography";

.rating {
  $root: &;
  margin: 0 0 80px;
  // margin: var(--block-space-s);

  &__header {
    margin: 0 0 40px;
    padding: 20px var(--offside-s);
    background-color: var(--light-gray);
    border-bottom: 2px solid var(--border-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    color: var(--dark);
  }

  &__container {
    margin: 20px var(--offside-s);
  }

  &__banner {
    width: 100%;
    min-height: 150px;
    background-color: var(--second-accent);
    border-radius: 7px;
    border: 2px solid var(--border-dark);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    @include typography.title4;
    color: var(--dark);
    text-align: center;
    max-width: 60%;
  }

  &__charts,
  &__rating {
    margin-top: 50px;
  }

  &__chart {
    margin-top: 20px;
  }

  &__head {
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &__inner {
    // border-bottom: 2px solid var(--light-gray);

    margin-bottom: 10px;
    // border-radius: 7px;
    padding: 10px;
  }

  &__list {
    @include common.reset-list;
    padding: 20px 0 0;
    // border-top: 2px solid var(--border-dark);
  }

  &__item {
    @include typography.title3;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // border-bottom: 2px solid var(--light-gray);
    padding-bottom: 4px;
    gap: 30px;

    &:nth-child(1) {
      #{$root}__name {
        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: var(--gold);
          margin-left: 5px;
          border-radius: 25%;

          position: relative;
        }
      }
    }

    &:nth-child(2) {
      #{$root}__name {
        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: var(--silver);
          margin-left: 5px;
          border-radius: 25%;

          position: relative;
        }
      }
    }

    &:nth-child(3) {
      #{$root}__name {
        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: var(--bronze);
          margin-left: 5px;
          border-radius: 25%;

          position: relative;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name,
  &__count {
    display: block;
    position: relative;
    color: var(--dark);
  }

  &__line {
    display: inline-flex;
    flex-grow: 1;
    background-color: aqua;
    display: flex;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: var(--light-gray);
    }
  }
}
