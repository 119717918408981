@use "src/common/typography";
@use "src/common/common";

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  max-width: 100vw;
  max-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s linear;

  &_opened {
    visibility: visible;
    opacity: 1;
  }

  &__container {
    padding: 20px;
    max-width: 430px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    box-sizing: border-box;
    position: relative;
    column-gap: 20px;

    @include common.media(md) {
      padding: 20px;
      margin: 0 20px;
    }
  }

  &__title {
    color: var(--dark);
    @include typography.title3-bold;
    margin: 0;
    display: inline-flex;
  }

  &__close-btn {
    @include common.button;

    border: 1px solid var(--dark);
    border-radius: 50%;
    transition: opacity 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    background-image: url("../../../assets//images/icons/close-icon.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 0 30px;
    flex-shrink: 0;
    flex-grow: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include common.media(md) {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
