@use "src/common/common";

.button-round {
  $root: &;
  @include common.button;

  width: 37px;
  height: 37px;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: transform 0.4s linear, opacity 0.2s linear;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  align-items: center;
  gap: 5px;

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  &_type {
    &_grey {
      background-color: var(--light-gray);
    }

    &_blue {
      background-color: var(--second-accent);
    }

    &_green {
      background-color: var(--second-accent);
    }
  }

  &_icon {
    &_edit {
      background-image: url("../../../assets/images/icons/edit-text.png");
    }

    &_plus {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--border-dark);
        border-radius: 3px;
      }

      &::before {
        width: 2px;
        height: 50%;
        transition: transform 0.2s ease-out;
      }

      &::after {
        width: 50%;
        height: 2px;
      }
    }

    &_minus {
      background-image: url("../../../assets/images/icons/minus.svg");
    }

    &_complete {
      background-image: url("../../../assets/images/icons/accept.png");
    }

    &_team {
      background-image: url("../../../assets/images/icons/team.png");
    }

    &_return {
      background-image: url("../../../assets/images/icons/return.png");
    }

    &_exit {
      background-image: url("../../../assets/images/icons/logout.svg");
    }

    &_delete {
      background-image: url("../../../assets/images/icons/exit.svg");
    }

    &_finish {
      background-image: url("../../../assets/images/icons/flag.png");
    }

    &_play {
      background-image: url("../../../assets/images/icons/play.png");
    }

    &_admin {
      background-image: url("../../../assets/images/icons/admin.png");
    }
  }

  &_size {
    &_small {
      width: 28px;
      height: 28px;

      #{$root} {
        &_icon_plus {
          &::before {
            width: 2px;
          }
          &::after {
            height: 2px;
          }
        }
      }
    }

    &_stretch {
      height: 28px;
      width: auto;
      padding: 0 6px;
    }
  }

  &_active.button-round_icon_plus {
    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
